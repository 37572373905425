<template>
  <v-dialog
    v-model="isVisibleDialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-container fluid>
        <div class="text-center">
          <span class="text-h3">{{ $t("locations_map") }}</span>
        </div>
      </v-container>
      <section>
        <locations-map :items="mapData" />
      </section>
      <v-container
        fluid
        class="d-flex justify-end"
      >
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    LocationsMap: () => import("../utils/LocationsMap.vue"),
  },
  props: {
    isVisibleDialog: Boolean,
    toggleEdit: {
      type: Function,
      default() {
        return void 0;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      mapData: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
  },
  mounted() {
    this.mapData = [...this.items];
  },
  methods: {
    isPermission(val) {
      return this.$admin.can(val);
    },
    closeForm() {
      this.toggleEdit();
    },
  },
};
</script>
